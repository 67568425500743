export default {
    path: "fileMaintenance",
    component: () => import( /* webpackChunkName: "fileMaintenanceMain" */ "@/modules/fileMaintenance/FileMaintenanceMain.vue"),
    meta: {
        include: true,
        label: "File",
        requiresOrder: true,
        scrollLayout: false,
    },
    children: [
        {
            path: "dashboard",
            name: "titlecompany:fmdashboard",
            component: () =>
            import(
                /* webpackChunkName: "dashboard" */ "@/modules/fileMaintenance/Dashboard.vue"
            ),
            meta: {
                requiresAuth: true,
                theme: "blue",
                label: "Dashboard",
            }
        },
        {
            path: "invitation",
            name: "titlecompany:fminvitation",
            component: () =>
            import(
            /* webpackChunkName: "invitation" */ "@/modules/fileMaintenance/Invitation.vue"
            ),
            meta: {
                requiresAuth: true,
                theme: "blue",
                label: "Invitations",
            }
        },
        {
            path: ":fileNum/invitation",
            redirect: { name: "titlecompany:fminvitation"}
        },
        {
            path: "fileDocuments",
            name: "titlecompany:fmfilesdocument",
            component: () =>
            import(
                /* webpackChunkName: "documents" */ "@/modules/fileMaintenance/DocumentMaintenance.vue"
            ),
            meta: {
                requiresAuth: true,
                theme: "blue",
                label: "Documents",
            }
        },
        {
            path: ":fileNum/fileDocuments",
            redirect: { name: "titlecompany:fmfilesdocument"}
        },
        {
            path: "milestones",
            name: "titlecompany:fmmilestone",
            component: () =>
            import(
                /* webpackChunkName: "milestones" */ "@/modules/fileMaintenance/MilestoneMaintenance.vue"
            ),
            meta: {
                requiresAuth: true,
                theme: "blue",
                label: "Milestones",
            }
        },
        {
            path: ":fileNum/milestones",
            redirect: { name: "titlecompany:fmmilestone"}
        },
    //   {
    //     path: "econsentLog",
    //     name: "titlecompany:fmfilesdocument:econsentlog",
    //     component: () =>
    //       import(
    //         /* webpackChunkName: "econsentlog" */ "@/modules/fileMaintenance/components/EconsentLog.vue"
    //       ),
    //     meta: {
    //       requiresAuth: true,
    //       theme: "blue",
    //       label: "eConsent Disclosure Log",
    //       matchRoutePrefix: "titlecompany:fmfilesdocument",
    //     }
    //   },
        {
            path: "overrides",
            name: "titlecompany:fmoverrides",
            meta: {
                requiresAuth: true,
                theme: "blue",
                label: "Overrides",
                scrollLayout: true
            },
            component: () =>
                import(
                    /* webpackChunkName: "overridesmain" */ "@/modules/fileMaintenance/overrides/OverridesMain.vue"
                ),
            children: [
                {
                    path: "contacts",
                    name: "titlecompany:fmoverrides:contacts",
                    component: () =>
                        import(
                            /* webpackChunkName: "contactoverrides" */ "@/modules/fileMaintenance/overrides/contacts/OverrideContactGrid.vue"
                        ),
                    meta: {
                        requiresAuth: true,
                        theme: "blue",
                        label: "Contacts",
                    }
                },
                {
                    path: "staff",
                    name: "titlecompany:fmoverrides:staff",
                    component: () =>
                        import(
                            /* webpackChunkName: "staffoverrides" */ "@/modules/fileMaintenance/overrides/staff/OverrideStaffGrid.vue"
                        ),
                    meta: {
                        requiresAuth: true,
                        theme: "blue",
                        label: "Staff",
                    }
                },
                {
                    path: "buyerseller",
                    name: "titlecompany:fmoverrides:buyerseller",
                    component: () =>
                        import(
                            /* webpackChunkName: "buyerselleroverride" */ "./shared/components/BuyerSellerOverrideGrid.vue"
                        ),
                    meta: {
                        requiresAuth: true,
                        theme: "blue",
                        label: "Buyer/Seller",
                    },
                },
                {
                    path: "property",
                    name: "titlecompany:fmoverrides:property",
                    component: () =>
                        import(
                            /* webpackChunkName: "propertyoverride" */ "./shared/components/PropertyMaintenanceForm.vue"
                        ),
                    meta: {
                        requiresAuth: true,
                        theme: "blue",
                        label: "Property",
                    },
                },
                {
                    path: "wireinstructions",
                    name: "titlecompany:fmoverrides:wireinstructions",
                    component: () =>
                        import(
                            /* webpackChunkName: "wireinstructionoverrides" */ "@/modules/fileMaintenance/overrides/wireinstructions/OverrideWiringInstructions.vue"
                            ),
                    meta: {
                        requiresAuth: true,
                        theme: "blue",
                        label: "Wire Instructions",
                    },
                },
            ]
        }
    ]
}